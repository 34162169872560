import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import InstaFeed from './Instagram/InstaFeed';
import Insta from "./Insta";
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import Customer from './Customer';
import ShopDiamondShape from "./ShopDiamondShape";
import Gemstones from "./GemstoneShop";
import RingStyle from "./RingStyle";
import Ringbuilderhome from "./Ringbuilderhome";
import SignupTo from "./SignupTo";
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import { useSelector } from 'react-redux';
import ComeAndSee from "./ComeAndSee";
// import SpecialHighlight from "./SpecialHighlight";
import MeetOurTeam from "./MeetOurTeam";
import FAQ from "../Pages/Static/faq";
import WatchBanner from "./WatchBanner";


const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)
  useEffect(() => {
    AOS.init({
      duration : 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content="Hemming Jewelers" />
        <link rel="canonical" href="https://hemmingjewelers.com/" />
      </Helmet>
      <BannerSlider />
      {/* <SpecialHighlight/> */}
      <RingStyle /> 
     
      <Customer />
     
    
      <ComeAndSee />
      <MeetOurTeam/>
      <InstaFeed token ="IGQVJYM2g3NHZAvTFgtUnVDc2F1cVJnWm90eVBmOS1MOWhtRzd5MXV4M0xteWtFUzU3enoyTHJPRGs3U0cweWpLd29NYUNCWUl0MDc5R2w3cFVZAUnZA0T0V2VFNnaVREalFlalg0aW1B" limit={50} />  
       {/* <WatchBanner/> */}
        {/* <Insta/>   */}
      {/* <AboutSection/> */}
      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/*  */}
      {/* <Newsletter /> */}
      <FAQ/>
    </>
  )
}

export default Home;