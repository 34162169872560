import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { AiFillStar } from 'react-icons/ai';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import fine from '../../Assets/images/hemming/financal.png';
import leftIcon from '../../Assets/images/left_icon.png'
import rightIcon from '../../Assets/images/right_icon.png'

const Customer = () => {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        autoplay: true,
        slickPrev: <img src={leftIcon} alt="slick button" />,
        slickNext: <img src={rightIcon} alt="slick button" />,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <>
            <section className='hj_ratings'>
                <Container>
                    <div className='words'>
                        <div className='customers'>
                            <h2>WORDS FROM OUR CUSTOMERS</h2>
                            <a href='#'>
                                <img src={fine} alt='fin'></img>
                            </a>
                            <div className='start mb-3'>
                                <ul>
                                    <li><AiFillStar /></li>
                                    <li><AiFillStar /></li>
                                    <li><AiFillStar /></li>
                                    <li><AiFillStar /></li>
                                    <li><AiFillStar /></li>
                                </ul>
                            </div>


                            <div className='rivew_slilder'>
                                <Slider {...settings} >
                                    <div className='sliders' onClick={() => window.open("https://www.google.com/search?q=hemming+hewelers&oq=hemming+hewelers&aqs=chrome..69i57j0i13i512l4j0i8i13i30j0i390l4.8599j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88e5b717bb884c69:0xf9ee219a397122ab,1,,,,", "_blank")}>
                                        <div className='overlay'>
                                            <p>Great experience! Walked in on a whim, walked out with a pair of beautiful earrings. Great service, attentive but no pressure to buy, upsell. I will be back.</p>
                                            <p class="testim">Mila Vega</p>
                                        </div>
                                    </div>
                                    <div className='sliders' onClick={() => window.open("https://www.google.com/search?q=hemming+hewelers&oq=hemming+hewelers&aqs=chrome..69i57j0i13i512l4j0i8i13i30j0i390l4.8599j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88e5b717bb884c69:0xf9ee219a397122ab,1,,,,", "_blank")}>
                                        <div className='overlay'>
                                            <p>Hands Down the best Jewelers in Jacksonville Florida. Customer service is always above and beyond. The staff from Juan, Misty, and Kirk are the best. Any Jewelry you need created, fix or simply buying these guys have it all. Best in town.</p>
                                            <p class="testim">Rashad Bivins</p>
                                        </div>
                                    </div>
                                    <div className='sliders'>
                                        <div className='overlay' onClick={() => window.open("https://www.google.com/search?q=hemming+hewelers&oq=hemming+hewelers&aqs=chrome..69i57j0i13i512l4j0i8i13i30j0i390l4.8599j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88e5b717bb884c69:0xf9ee219a397122ab,1,,,,", "_blank")}>
                                            <p>I’ve had several pieces made and repaired by Hemming Plaza Jewelers, and have always been very satisfied! The quality of craftsmanship is excellent and they always finish before the time frame given. I will continue using Hemming Plaza as my jeweler, and would suggest you do the same.</p>
                                            <p class="testim">Luke Mills</p>

                                        </div>
                                    </div>
                                    <div className='sliders'>
                                        <div className='overlay' onClick={() => window.open("https://www.google.com/search?q=hemming+hewelers&oq=hemming+hewelers&aqs=chrome..69i57j0i13i512l4j0i8i13i30j0i390l4.8599j0j7&sourceid=chrome&ie=UTF-8#lrd=0x88e5b717bb884c69:0xf9ee219a397122ab,1,,,,", "_blank")}>
                                            <p> Hemming Jewelers is definitely a Jacksonville gem. I've always been satisfied with every purchase and the customer service - Misty is the best!. Everyone who has assisted me were so helpful, knowledgeable and patient! I am looking forward to more purchases with them in the future.</p>
                                            <p class="testim">Tamara Junean</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Customer;
