import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../Assets/css/home.css';
import Slider from 'react-slick';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Jack from '../../Assets/images/hemming/indexbannerdesktop.webp';
import JackMobile from '../../Assets/images/hemming/indexbannerMobile.webp';

// import Xmas from '../../Assets/images/valentine-slider.jpg';
// import XmasMob from '../../Assets/images/valentine-mobiile.jpg';
import FatherDay from '../../Assets/images/father-day.jpg';
import FatherDayMobile from '../../Assets/images/banner-mob.jpg';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { useState } from 'react';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { toast } from 'react-toastify';

const BannerSlider = () => {
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [banner, setBanner] = useState('')
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setLoader(true)
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          // setBanner(response.data.data)
          setLoader(false)
          // localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }, [])
  // console.log(banner);
  // const banner = localStorage.getItem('bw-bannerdata') ? JSON.parse(localStorage.getItem('bw-bannerdata')) : [];
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;
  return (
    <>
      <section className="hj_baner">
        <Slider {...settings}>
          {/* <div className="item">
            <img src={FatherDay} alt="" className="d-md-block d-none" />
            <img src={FatherDayMobile} alt="" className="d-md-none d-block" />
            
          </div> */}
          {/* <img src={XmasMob} alt="" className="d-md-none d-block" /> */}
          <div className="item">

          {isMobileOnly?  <img src={JackMobile} alt="" /> :<img src={Jack} alt="" />  }  
          </div>
        </Slider>
      </section>
      {/* <section className='hj_greybackground_Jasckon'>
        <Container>
          <div className='img_logo'>
            <img src={Jack} alt='log'></img>
          </div>    
        </Container>
      </section> */}
    </>
  );
};

export default BannerSlider;
