import { IoCall } from "react-icons/io5";

const FloatingContactNumber = () => {
    return (
      <div className="floating-contact">
         <a href="tel:+19043545959"><IoCall /></a>
      </div>
    );
  };
  
  export default FloatingContactNumber;