import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import "../../../src/Assets/css/hemming.css";

const ComeAndSee = () => {
    const history = useHistory();
    return (
        <>
            <section className='map_c'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12} md={6}>
                            <div className='map_com'>
                                <div className='map_see'>
                                    <h2> COME AND SEE US</h2>
                                    <p> Hemming Jewelers is a family-owned jeweler located in Downtown<br></br> Jacksonville at the corner of Hogan and Monroe,
                                        across the street<br></br> from Hemming Park. We have been proudly serving Jacksonville <br></br>since 2003.
                                        Come see us – we would love to help you find or create<br></br> the perfect piece of jewelry.</p>
                                    <Button variant="outline-primary" onClick={() => history.push("/location")} className='location_but'>Our Location</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} sm={12} md={6}>
                            <div className='map_direct'>
                                <div className='hem_map'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13775.092983008535!2d-81.66465260321047!3d30.328967041972977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf9ee219a397122ab!2sHemming+Plaza+Jewelers!5e0!3m2!1sen!2sus!4v1524002134528"
                                        frameborder="0" ></iframe>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ComeAndSee;