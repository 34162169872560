
import React, { useState, useEffect } from 'react'
import "../../../Assets/css/insta.css";
import axios from 'axios'
import Feed from './Feed'
import Slider from 'react-slick'
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { Container, Row, Col } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";


const InstaFeeds = (props) => {
  // let settings = {
  //   arrows: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   draggable: false,
  //   slidesToShow: 5,
  //   slidesToScroll: 5,
  //   prevArrow: <BsChevronLeft />,
  //   nextArrow: <BsChevronRight />,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 5,
  //       }
  //     },
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3
  //       }
  //     },
  //     {
  //       breakpoint: 575,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     }
  //   ]
  // };

  // const [feeds, setFeedsData] = useState([])

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   async function fetchInstagramPost() {
  //     try {
  //       axios
  //         .get(`https://graph.instagram.com/me/media?fields=id,thumbnail_url,username,follows_count,media_count,profile_pic_url,name,media_type,media_url,caption&limit=${props.limit}&access_token=${props.token}`)
  //         .then((resp) => {
  //           setFeedsData(resp?.data?.data)
  //         })
  //     } catch (err) {
  //       console.log('error', err)
  //     }
  //   }

  //   fetchInstagramPost();

  //   return () => {
  //     abortController.abort();
  //   };
  // }, [props.limit])


  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="hemming_insta_title w-100 mt-5 mb-5">
              <h2 className="text-center">View our Instagram</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='p-0'>
            <div>
              <iframe src="https://instagram.demobw.live/hemming-jewelers/" frameborder="0" width="100%" class="insta-iframe"></iframe>
            </div>
          </Col>

        </Row>
      </Container>
      {/* <div className="feed_container mb-5">
           <Slider className='slider-insta' {...settings}>
               {feeds.map((feed) => (
                   <div className='slide-items'>
                       <Feed key={feed.id} feed={feed}  modalFeeds={feeds} />
                       </div>
               ))}
           </Slider>
       </div> */}

    </>
  );
}
export default InstaFeeds;
