import React from 'react'
import ScrollToTop from "react-scroll-to-top";
import { FiArrowUp } from 'react-icons/fi'

const BackToTop = () => {
    return (
        <>
            <div className='hj_back_to_top_button'>
                <ScrollToTop smooth color="#6f00ff" ><FiArrowUp /></ScrollToTop>
            </div>

        </>
    )
}

export default BackToTop