import { React, useState , useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap'
import jaunImg from '../../Assets/images/hemming/team-imgs/Juan.webp'
import KirkImg from '../../Assets/images/hemming/team-imgs/Kirk.webp'
import MistyImg from '../../Assets/images/hemming/team-imgs/Misty.webp'
import GodivaImg from '../../Assets/images/hemming/team-imgs/Godiva.webp'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdOutlineMailOutline, MdEmail, MdPhone } from "react-icons/md";
import { Link } from 'react-router-dom';


const MeetOurTeam = () => {

  const [show, setShow] = useState(false);
  const [dataDetails , setDataDetails] = useState([]) 
  const handleClose = () => setShow(false);
  const teamData = [
    { 
      id : 1,
      slug: "juan",
      name: "Juan",
      designation: "Owner/ Auctioneer",
      img: jaunImg,
      email: 'juan@hemmingplazajewelers.com' 
    },
    {
      id : 2,
      slug: "kirk",
      name: "Kirk",
      designation: "Jewelry Designer/ Owner",
      img: KirkImg,
      email: 'kire@hemmingplazajewelers.com' 
    },
    {
      id : 3,
      slug: "misty",
      name: "Misty",
      designation: "Diamond Grader/ Colored Gemstone Specialist/ Appraiser/ Jewelry Designer",
      img: MistyImg,
      email: 'misty@hemmingplazajewelers.com' 
    },
    {
      id : 4,
      slug: "godiva",
      name: "Godiva ",
      designation: "Jewelry Specialist/ Communications/ Social Media Manager",
      img: GodivaImg,
      email: 'godiva@hemmingplazajewelers.com'  
    },
  ]

  const handleShow = (id) => {
    const filterData = teamData.filter(item => item.id === id)
    setDataDetails(filterData)
    setShow(true)
  };

  return (
    <>
      <div className='hm_meet_our_team_section'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="hemming_insta_title w-100 mt-5 mb-4"><h2 className="text-center">Meet our team</h2></div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className='hm_meet_team_box'>
                <ul className=' d-sm-block d-md-block d-lg-flex'>
                  {teamData.map((value, index) => (
                    <li key={index}>
                      <Link to={`/team/${value.slug}`}>
                      <div className='hm_meet_team_box_inner'>
                        <div>
                          <Image src={value.img} alt={value.name + "Image"} className='img-fluid' />
                        </div>
                        <div className='hm_team_text_box'>
                          <h6>{value.name} </h6>
                          <p>{value.designation}</p>
                        </div>

                      </div>
                      </Link>
                  
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


      <Modal  size="lg" show={show} onHide={handleClose} className='meet_out_modal_main'>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{padding:'0px'}}>
            <Row>
              <Col lg={12} xs={12}  >
                {dataDetails.map((value, index) => (
                  <div className='meet_team_body'>
                    <div className='hm_meet_team_box_inner modal_view'>
                      <div>
                        <Image src={value.img} alt={value.name + "Image"} className='img-fluid' />
                      </div>

                      <div className='hm_team_text_box'>
                        <h6>{value.name} </h6>
                        <p style={{fontWeight: '400'}}>{value.designation}</p>
                        <p> <MdPhone /> Phone : <span   onClick={(e) => {window.location.href = `tel:9053545959`;}}> 905 354 5959 </span> </p>
                        <p> <MdEmail /> Email : <span onClick={(e) => { window.location.href = `mailto:${value.email}` }}> {value.email} </span> </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
       
      </Modal>

    </>

  )
}

export default MeetOurTeam
