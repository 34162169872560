import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useHistory } from 'react-router-dom';

import Square from '../../Assets/images/hemming/diamond1.png';
import Ring from '../../Assets/images/hemming/diamond2.png';
import Rosering from '../../Assets/images/hemming/diamond3.png';
import dir from '../../Assets/images/hemming/diamond4.jpg';

const RingStyle = () => {
    var history = useHistory();

    const shopbystyle = (value) => {
        var data1 = {
            style: value,
        }
        sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
        history.push('/ringsettings/diamonds');
    }
    return (
        <>
            <section className='hj_diamrings'>
                <Container>
                    <Row>
                        <Col md={3}>
                            <div className='four_jewllery_imgs'>
                                <div className='diamonds'>
                                    <Link to='/diamonds'>
                                        <h3>DIAMONDS</h3></Link>
                                    <div className='bridal_jewl'>
                                        <Link to='/diamonds'>
                                            <img src={Square} alt='rings'></img>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='four_jewllery_imgs'>
                                <div className='diamonds'>
                                    <Link to='/ringsettings'>
                                        <h3>BRIDAL</h3></Link>
                                    <div className='bridal_jewl'>
                                        <Link to='/ringsettings'>
                                            <img src={Ring} alt='BRIDAL img'></img>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='four_jewllery_imgs'>
                                <div className='diamonds'>
                                    <Link to='/custom-design'>
                                        <h3>CUSTOM JEWELRY</h3></Link>
                                    <div className='bridal_jewl'>
                                        <Link to='/custom-design'>
                                            <img src={Rosering} alt='CUSTOM img'></img>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='four_jewllery_imgs'>
                                <div className='diamonds'>
                                    <Link to='/services'>
                                        <h3>SERVICES</h3></Link>
                                    <div className='bridal_jewl'>
                                        <Link to='/services'>
                                            <img src={dir} alt='SERVICES img'></img>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default RingStyle;